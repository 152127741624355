import { ApiUser } from '@vend/types'
import { trackError } from '../tracking/errorTracker'

let channel: BroadcastChannel | null = null

type UserSwitchMessage = {
  action: 'user_switch'
  user: ApiUser
}

const getChannel = (): BroadcastChannel | null => {
  if ('BroadcastChannel' in window && !channel) {
    channel = new BroadcastChannel('user')
  }

  return channel
}

// Broadcast a user switch event across tabs
export const broadcastUserSwitchEvent = (user: ApiUser) => {
  const message: UserSwitchMessage = { action: 'user_switch', user }
  const userChannel = getChannel()
  if (userChannel) {
    userChannel.postMessage(message)
  }
}

// Subscribe to user switch events
export const subscribeToUserSwitchEvents = (
  onSwitch: (user: ApiUser) => void
) => {
  const userChannel = getChannel()

  if (!userChannel) {
    return () => {}
  }

  const handle = (event: MessageEvent<UserSwitchMessage>) => {
    if (event.data.action === 'user_switch') {
      onSwitch(event.data.user)
    }
  }

  // Register the listener
  userChannel.addEventListener('message', handle)

  userChannel.onmessageerror = event => {
    trackError('error receiving user switch event', event)
  }

  // Return a cleanup function
  return () => {
    userChannel.removeEventListener('message', handle)
  }
}
